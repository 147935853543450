import { SQUAD_01_20240903_MIGRATION_HIRE_REGISTER_CREATE_OFFER } from '~/config/featureFlags'

export const useCreateOffer = () => {
	const { $auth, $hasFeature } = useNuxtApp()

	const createOfferPath = computed<string>(() => {
		if (
			!$auth.user.business.has_sent_first_offer &&
			$hasFeature(SQUAD_01_20240903_MIGRATION_HIRE_REGISTER_CREATE_OFFER)
		) {
			return '/offer/create'
		}

		return '/offer/import'
	})

	return { createOfferPath }
}
